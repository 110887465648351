<template>
  <button class="btn btn-primary" @click="onSubmit">Submit</button>
  <div style="background:#fff; height:300px; overflow:auto; padding:20px;">
    <pre>
      {{ $v }}
    </pre>
  </div>
</template>

<script>
import { required, minLength } from "@vuelidate/validators";
//import { settings } from "../shared";

export default {
  data() {
    return {
      people: [
        {
          name: "John"
        },
        {
          name: null
        }
      ]
    };
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
    },
    setSelected(city) {
      this.city = city;
    }
  },
  validations: {
    people: {
      required,
      minLength: minLength(3),
      every: {
        name: {
          required,
          minLength: minLength(2)
        }
      }
    }
  }
};
</script>

<style></style>
